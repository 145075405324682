import PropTypes from 'prop-types';
import React from 'react';

const YouTubeVideo = ({videoId, title, className, iFrameProps, ...rest}) => {
    return (
        <div {...rest} className={`ratio ratio-16x9 ${className}`}>
            <iframe
                allowFullScreen
                {...iFrameProps}
                src={`https://www.youtube.com/embed/${videoId}?hl=de&autoplay=0&modestbranding=1&rel=0&controls=1`}
                title={title}
                allow="autoplay; fullscreen; picture-in-picture"/>
        </div>
    );
};

YouTubeVideo.propTypes = {
    videoId: PropTypes.string.isRequired,
    title: PropTypes.string,
    className: PropTypes.string,
    iFrameProps: PropTypes.object,
};

YouTubeVideo.defaultProps = {
    className: '',
}

export default YouTubeVideo;
