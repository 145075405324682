import {useEffect, useRef} from 'react';
import ScrollSpy from 'bootstrap/js/dist/scrollspy';

export function usePageScrollSpy(offset = 100) {
    const navRef = useRef(null);
    useEffect(() => {
        const sp = new ScrollSpy(document.body, {
            target: navRef.current,
            offset,
        });
        return () => {
            sp.dispose();
        }
    }, [offset]);
    return navRef;
}

